import * as React from "react";
import { useEffect } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { APP_URL, SIGNUP_URL } from "constants/paths";
import Cookies from "universal-cookie";
import { AUTHENTICATION_COOKIE_NAME } from "constants/cookies";
import ReadPost from "components/ui/base/ReadPost";
import PlainHeader from "components/ui/base/PlainHeader";
import AIBenefits from "components/ui/extended/AIBenefits";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import NumberSteps from "components/ui/base/NumberSteps";
import FullWidthImage from "components/ui/base/FullWidthImage";
import Awards from "components/ui/extended/Awards";
import PlatformFeatures from "components/ui/extended/PlatformFeatures";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";

interface IndexPageProps {
  location: Location;
}

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const cookies = new Cookies(document.cookie);

    if (cookies.get(AUTHENTICATION_COOKIE_NAME) !== undefined) {
      window.location.replace(APP_URL);
    }
  }, []);

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.index.seo.title")}
        description={t("pages.index.seo.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-behavioural-chats-emails-and-surveys-saas.jpg",
          alt: "Customer Operating System for SaaS",
        }}
      />

      <PlainHeader
        title={t("pages.index.title")}
        titleMaxWidth={1060}
        descriptionMaxWidth={900}
        description={t("pages.index.description")}
        ctaRegister={{ show: true }}
        image={{
          relativePath: "pages/index/AI-customer-service-solution.jpg",
          alt: "Customer support Chatbot with AI",
        }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <Customers />

      <AIBenefits />

      <NumberSteps
        title={t("pages.index.steps.title")}
        elements={Array.from(Array(4).keys()).map((i) => ({
          title: t(`pages.index.steps.${i + 1}.title`),
          description: t(`pages.index.steps.${i + 1}.description`),
        }))}
      />

      <FullWidthImage
        title={t("pages.index.help-desk.title")}
        description={t("pages.index.help-desk.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        descriptionMaxWidth={900}
        image={{
          relativePath: "pages/index/help-desk-status.jpg",
          alt: t("pages.index.help-desk.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.index.aura-training.title")}
        description={t("pages.index.aura-training.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/AI-learn.jpg",
          alt: t("pages.index.aura-training.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <ImageWithDescription
        title={t("pages.index.escalation.title")}
        description={t("pages.index.escalation.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/AI-smart-escalation.jpg",
          alt: t("pages.index.escalation.title"),
          fullWidth: true,
          position: "left",
        }}
      />

      <FullWidthImage
        title={t("pages.index.missions.title")}
        description={t("pages.index.missions.description")}
        descriptionMaxWidth={700}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath: "pages/index/ai-mission-setup-refund.jpg",
          alt: t("pages.index.missions.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.index.automate.title")}
        description={t("pages.index.automate.description")}
        cta={{
          link: SIGNUP_URL,
          text: t("components.ui.extended.RegisterCTA.getStarted"),
        }}
        image={{
          relativePath:
            "pages/index/full-scale-support-automation-with-ai-chatbot.jpg",
          alt: t("pages.index.automate.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <ReadPost slug={"best-ai-live-chat-software"} />

      <PlatformFeatures location={location} />

      <TestimonialWithImageLaurentiu />
      <Awards />
      <RegisterForCustomerService />
      <Customers />
    </GlobalWrapper>
  );
};
